.ql-toolbar.ql-snow {
  border: solid;
  border-color: rgba(0, 0, 0, 0.25);
  border-width: 0px 0px 1px 0px;
  font-family: roboto;
  margin-bottom: 6px;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
}

.ql-container.ql-snow {
  border: none;
  border-bottom: 0px solid rgba(0, 0, 0, 0.42);
}
.ql-container.ql-snow:hover {
  border: none;
  border-bottom: 0px solid rgb(0, 0, 0);
  margin-bottom: 0px;
}
.ql-container.ql-snow:active {
  border: none;
  border-bottom: 0px solid rgb(0, 0, 0);
  margin-bottom: 0px;
}
.ql-editor {
  padding-left: 2px;
  padding-right: 0px;
  padding-bottom: 3px;
  padding-top: 0px;
  font-family: roboto;
  font-size: 16px !important;
}

.ql-editor.ql-blank::before {
  color: #979797;
  content: attr(data-placeholder);
  font-style: normal;
  font-family: roboto;
  font-size: 1rem;
  pointer-events: none;
  position: absolute;
}

.hide-toolbar .ql-toolbar {
  display: none;
}
